import { LOCALES } from '../constants';

export default {
  [LOCALES.FRENCH]: {
    'hello': 'Nyob zoo',
    'login': 'Nkag mus',
    'signup': 'Kos npe',
    'computer': 'Computer',
    'projector': 'Projector',
    'printer': 'Tshuav luam ntawv',
    'camera': 'Tshuab yee duab',
    'mobile': 'Xov tooj',
    'mouse': 'Mouse',
    'memory': 'Memory',
    'whatch': 'Moo',
    'clothing': 'Khaub ncaw',
    'shoes': 'Khaus',
    'bag': 'Hnab',
    'book': 'Ntawv',
    'car': 'Ntsheb',
    'motocycle': 'Ntsheb cav',

    'logOut': 'Tawm mus',
    'myOrders': 'Cov khoom kuv yuav',
    'firstName': 'Koj lub npe',
    'lastName': 'Xeem',
    'password': 'Zauv',
    'confirmPassword': 'Rau koj tu zauv dua ib zaug',
    'forgotPassword': 'Hnov qab zauv',
    'createNewAccout': 'Tsim ab khaub tshiab',
    'email': 'Email',
    'send': 'Xa mus',
    
    'redeem': 'Dau',
    
    
    'deliveredAddress': 'Xa rau koj qhov chaw nyob',
    'paymentType': 'Them nyiaj hom twg',
    'paymentStatus': 'Cov xwm txheej them nqi',
    'orderedOn': 'Khoom koj tau yuav',
    'orderTotal': 'Koj cov khoom tu nqi tag nrho yog',
    'order': 'Yuav',
    'saveAndDeliveryHere': 'Kaw cia thiab xa rau nov',
    'alternatePhoneOptional': 'Koj tus xov tooj(tsis rau lo tau)',
    'landmarkOptional': 'Lub Tebchaws (tsis rau lo tau)',
    'state': 'Xeev',
    'cityDistrictTown': 'Nroog',
    'addressAreaAndStreet': 'Chaw nyob (thaj tsam & txoj kev)',
    'locality': 'Zos',
    'pincode': 'Pincode',
    '10-digitMobileNumber': 'Raus xov tooj kom muaj 10 tus lej',
    'fullName': 'Koj lub npe thiab xeem',
    'deliveryPrice': 'Tus nqis xa',
    'totalPrice': 'Tus nqi tag nrhos',
    'orderdetails': 'Cov ntsiab lus kom tseeb',
    'products': 'Khoom',
    'category': 'Txhua yam',
    'purchaseOrder': 'Purchase order',
    'hmong': 'Hmoob',
    'english': 'Fabkis',
    'laos': 'Nplog',
    'search': 'Tsawb fawb',
    'resetPassword': 'Rov qab kho tus zauv',
    'home': 'Tsev',
    
    'payment': 'Them nyiaj',
    'sorryOnlyCashOnDeliveryIsAvailable': 'Thov txim ntau os, Tsuas yog nyiaj xuv ntawm kev xa khoom nkaus xwb',
    'paymentConfirm': 'Them nqi kom paub',
    'productIsLoading': 'Tab tom rub cov khoom...!',
    'enterFirstName': 'Raus koj lub npe',
    'enterLastName': 'Raus koj lub xeem',
    'invalidEmailAddress': 'Koj tu email tsis raug lawm',
    'enterEmail': 'Raus koj tus email',
    'enterPassword': 'Raus koj tu zauv',
    'enterRepassword': 'Raus koj zauv dua',
    'passwordDosenotMatch': 'Koj ob tug zauv tsis sib nraug lawm',
    'thankUYouForYourOrder': 'Ua tsaug ua koj tseem pab txhawb nqa peb sabsab thiab nawb',
    'orderIdReceiveAnEmailConfirmationShortlyAtEmail': 'Koj yuav tau txais tsab ntawv hauv email',
    
    'somethingWentWrong': 'Muaj qee yam ua tsis yog lawm',
    'pending': 'Tseem nyob tos',
    'deliverHere': 'Xa tuaj rau qhov no',
    'addNewAddress': 'Raus chaw nyob tshiab',
    'submitOrder': 'Xa kev txiav txim',
    
    'orderIdIs': 'Koj tus ID yog',

    'software' : 'Software',
    'service': 'Kev sib pab',
    'course' : 'Kawm ntawv',
    '3d': '3D',
    'information' : 'Cov ntaub ntawv',
    'jobs': 'Cov hauj lwm',
    'contact': 'Sib cuag',
    'about' : 'Txog',
    'blog': 'Blog',

     //Home page
    'commingSoon':'Tseem nyob rau kev tsim kho...',

    //Product Detail
    'lak': ' Kij',
    'buyNow': ' Yuav tam siv no',
    'addToCart': ' Ntxiv rau lub tawb',
    'description' : 'Kev piav qhia:',
    'availableOffers' : 'Available Offers',
    'extra' : 'ພິເສດ',
    'off' : 'ປິດ',
    'ratings' : 'Ratings',
    'reviews' : 'Reviews',
    'ratings' : 'ການໃຫ້ຄະແນນ',
    'reviews' : 'ການທົບທວນຄືນ',

    //Cart Page
    'myCart': 'Kuv li khoom',
    'placeOrder': 'PLACE ORDER',

    //Cart Item
    'deliveryIn35Days':'Delivery in 3 - 5 days',
    'price': 'Tus nqi:',
    'remove': 'Tshem tawm',
    'saveForLater':'save for later',
    
    //PriceDetail
    'deliveryCharges':'Delivery Charges:',
    'items':'items',
    'free':'Dawb xwb',
    'totalAmount':'Tus nqi tag dho:',
    'priceDetails':'Price Details: ',

    //Top menu
    'newCustomer':'New Customer?',
    'explore':'Explore',
    'plus': 'Plus',
    'more':'More',
    'cart': 'Cart',

    //SignUp
    'or':'Los yog',
    'register': 'Register',

    //SIGNIN
    'requestOTP':'Request OTP',
    'emailMobile':"Email/Mobile Number",
    'enter': 'Enter',
    'getAccessToYourOrders':'Get access to your Orders, Wishlist and Recommendations',

     //Check out
    'addressType': 'Address Type',
    'homeAddress': 'Home',
    'workAddress': 'ວຽກ',
    'saveAndDeliverHere':'SAVE AND DELIVER HERE',
    'addNewAddress': 'ADD NEW ADDRESS',
    'deliveryAddress':'DELIVERY ADDRESS',
    'deliveryLogin': 'LOGIN',
    'paymentOptions': 'PAYMENT OPTIONS',
    'continue': 'Mus txiv',
    'deliveryHere':'ການຈັດສົ່ງມາທີ່ນີ້',
    'cashOnDelivery':'Cash on delivery',
    'confirmOrder':'CONFIRM ORDER',
    'phoneNumber':'Phone number',
    'moreActions':'More Actions',
    'downloadInvoice':'Download Invoice',
    'myAccount':'My Account',
    'myOrders':'ການສັ່ງຊື້ທັງໝົດ',
  },
};
