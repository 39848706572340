import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    'hello': 'Hello',
    'login': 'Login',
    'signup': 'SingUp',
    'logOut': 'Log out',
    'myOrders': 'My Orders',
    'firstName': 'First name',
    'lastName': 'Last name',
    'password': 'Password',
    'confirmPassword': 'Confirm Password',
    'forgotPassword': 'Forgot Password',
    'createNewAccout': 'Create new accout',
    'email': 'Email',
    'send': 'Send',
    
    'redeem': 'Redeem',
    
    
    'deliveredAddress': 'Delivered Address',
    'paymentType': 'Payment Type',
    'paymentStatus': 'Payment Status',
    'orderedOn': 'Ordered On',
    'orderTotal': 'Order Total',
    'order': 'Order',
    'saveAndDeliveryHere': 'SAVE AND DELIVER HERE',
    'alternatePhoneOptional': 'Alternate Phone (Optional)',
    'landmarkOptional': 'Landmark (Optional)',
    'state': 'State',
    'cityDistrictTown': 'City/District/Town',
    'addressAreaAndStreet': 'Address (Area and Street)',
    'locality': 'Locality',
    'pincode': 'Pincode',
    '10-digitMobileNumber': '10-digit Mobile Number',
    'fullName': 'Full Name',
    'deliveryPrice': 'Delivery Price',
    'totalPrice': 'Total Price',
    'orderdetails': 'Order details',
    'products': 'Products',
    'category': 'All Category',
    'purchaseOrder': 'Purchase order',
    'hmong': 'Hmong',
    'english': 'English',
    'laos': 'Laos',
    'search': 'Search',
    'resetPassword': 'Reset Password',
    'home': 'Home',
    
    'payment': 'Payment',
    'sorryOnlyCashOnDeliveryIsAvailable': 'Sorry, Only Cash on Delivery is available',
    'paymentConfirm': 'Payment Confirm',
    'productIsLoading': 'Product is loading...!',
    'enterFirstName': 'Enter First Name',
    'enterLastName': 'Enter Last Name',
    'invalidEmailAddress': 'Invalid Email Address',
    'enterEmail': 'Enter Email',
    'enterPassword': 'Enter Password',
    'enterRepassword': 'Enter Repassword',
    'passwordDosenotMatch': 'Password dosen\'t match',
    'thankUYouForYourOrder': 'Thank you for your order',
    'orderIdReceiveAnEmailConfirmationShortlyAtEmail': 'You will receive an email confirmation shortly at email@gmail.com',
    
    'somethingWentWrong': 'Something went wrong',
    'pending': 'Pending',
    'deliverHere': 'Deliver Here',
    'addNewAddress': 'Add new address',
    'submitOrder': 'Submit order',
    
    'orderIdIs': 'Order id is',

    'software' : 'Software',
    'service': 'Service',
    'course' : 'Course',
    '3d': '3D',
    'information' : 'Information',
    'jobs': 'Jobs',
    'contact': 'Contact',
    'about' : 'About',
    'blog': 'Blog',
     //Home page
    'commingSoon':'Comming Soon...',

    //Product Detail
    'lak': ' LAK',
    'buyNow': ' BUY NOW',
    'addToCart': 'Add to cart',
    'description' : 'Description:',
    'availableOffers' : 'Available Offers',
    'extra' : 'Extra',
    'off' : 'Off',
    'ratings' : 'Ratings',
    'reviews' : 'Reviews',

    //Cart page
    'myCart': 'My cart',
    'deliverTo': 'Deliver to',
    'placeOrder': 'PLACE ORDER',

    //Cart Item
    'deliveryIn35Days':'Delivery in 3 - 5 days',
    'price': 'Price:',
    'remove': 'Remove',
    'saveForLater':'save for later',
    'deliveryCharges':'Delivery Charges:',
    'items':'items',
    'free':'FREE',
    'totalAmount':'Total Amount:',
    'priceDetails':'Price Details:',

    //Top menu
    'newCustomer':'New Customer?',
    'explore':'Explore',
    'plus': 'Plus',
    'more':'More',
    'cart': 'Cart',

    //SignUp
    'or':'OR',
    'register': 'Register',

    //SIGNIN
    'requestOTP':'Request OTP',
    'emailMobile':"Email/Mobile Number",
    'enter': 'Enter',
    'getAccessToYourOrders':'Get access to your Orders, Wishlist and Recommendations',

    //Check out
    'addressType': 'Address Type',
    'homeAddress': 'Home',
    'workAddress': 'Work',
    'saveAndDeliverHere':'SAVE AND DELIVER HERE',
    'addNewAddress': 'ADD NEW ADDRESS',
    'deliveryAddress':'DELIVERY ADDRESS',
    'deliveryLogin': 'LOGIN',
    'paymentOptions': 'PAYMENT OPTIONS',
    'continue': 'CONTINUE',
    'deliveryHere':'DELIVERY HERE',
    'cashOnDelivery':'Cash on delivery',
    'confirmOrder':'CONFIRM ORDER',
    'phoneNumber':'Phone number',
    'moreActions':'More Actions',
    'downloadInvoice':'Download Invoice',
    'myAccount':'My Account',
    'myOrders':'ການສັ່ງຊື້ທັງໝົດ',
  },
};
