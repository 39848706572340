import React from "react";
import { FormattedMessage } from 'react-intl';
import Card from "../../components/UI/Card";

/**
 * @author
 * @function PriceDetails
 **/

const PriceDetails = (props) => {
  return (
    <Card headerLeft={<FormattedMessage id='priceDetails'/>} style={{ maxWidth: "380px" }}>
      <div
        style={{
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <div className="flexRow sb" style={{ margin: "10px 0" }}>
          <div><FormattedMessage id='price'/> ({props.totalItem} <FormattedMessage id='items'/>)</div>
          <div>{Intl.NumberFormat().format(props.totalPrice)}<FormattedMessage id='lak'/></div>
        </div>
        <div className="flexRow sb" style={{ margin: "10px 0" }}>
          <div><FormattedMessage id='deliveryCharges'/></div>
          <div><FormattedMessage id='free'/></div>
        </div>
        <div className="flexRow sb" style={{ margin: "10px 0" }}>
          <div><FormattedMessage id='totalAmount'/></div>
          <div>{Intl.NumberFormat().format(props.totalPrice)} <FormattedMessage id='lak'/></div>
        </div>
      </div>
    </Card>
  );
};

export default PriceDetails;
