import { LOCALES } from '../constants';

export default {
  [LOCALES.LAOS]: {
    'hello': 'ສະບາຍດີ',
    'login': 'ເຂົ້າ​ສູ່​ລະ​ບົບ',
    'signup': 'ລົງ​ທະ​ບຽນ',
    'computer': 'ຄອມພິວເຕີ',
    'projector': 'ໂປເຈັກເຕີ',
    'printer': 'ເຄື່ອງພິມ',
    'camera': 'ກ້ອງຖ່າຍຮູບ',
    'mobile': 'ມືຖື',
    'mouse': 'ເມົ້າສ',
    'memory': 'ຄວາມຈໍາ',
    'whatch': 'ໂມງ',
    'clothing': 'ເຄື່ອງນຸ່ງ',
    'shoes': 'ເກີບ',
    'bag': 'ກະເປົາ',
    'book': 'ປີ້ມ',
    'car': 'ລົດ',
    'motocycle': 'ລົດຈັກ',

    'logOut': 'ອອກຈາກລະບົບ',
    'myOrders': 'ລາຍການສັ່ງຊື້',
    'firstName': 'ຊື່​ແທ້',
    'lastName': 'ນາມສະກຸນ',
    'password': 'ລະຫັດຜ່ານ',
    'confirmPassword': 'ຢືນຢັນລະຫັດຜ່ານ',
    'forgotPassword': 'ລືມລະຫັດຜ່ານ',
    'createNewAccout': 'ສ້າງບັນຊີໃໝ່',
    'email': 'ອີເມວ',
    'send': 'ສົ່ງ',

    'redeem': 'ຈ່າຍຜ່ອນ',


    'deliveredAddress': 'ສະຖານທີ່ຈັດສົ່ງ',
    'paymentType': 'ປະເພດການຈ່າຍເງິນ',
    'paymentStatus': 'ສະຖານະການຈ່າຍເງິນ',
    'orderedOn': 'ລາຍການຊື້ແລ້ວ',
    'orderTotal': 'ລວມທັງໝົດ',
    'order': 'ສັ່ງຊື້',
    'saveAndDeliveryHere': 'ບັນທຶກ ແລະ ສົ່ງມາທີ່ນີ້',
    'alternatePhoneOptional': 'Alternate Phone (Optional)',
    'landmarkOptional': 'Landmark (Optional)',
    'state': 'ແຂວງ',
    'cityDistrictTown': 'ເມືອງ',
    'addressAreaAndStreet': 'ທີ່ຢູ່ (ສະຖານທີ່ ແລະ ຖະໜົນ)',
    'locality': 'ທ້ອງຖິ່ນ',
    'pincode': 'ພີມໂຄດ',
    '10-digitMobileNumber': 'ໝາຍເລກໂທລະສັບ 10 ຕົວເລກ',
    'fullName': 'ຊື່ ແລະ ນາມສະກຸນ',
    'deliveryPrice': 'ລາຄາການຂົນສົ່ງ',
    'totalPrice': 'ລວມທັງໝົດທີ່ຕ້ອງຈ່າຍ',
    'orderdetails': 'ລາຍລະອຽດການສັ່ງຊື້',
    'products': 'ສິນຄ້າ',
    'category': 'ປະເພດທັງໝົດ',
    'purchaseOrder': 'ໃບ​ສັ່ງ​ຊື້',
    'hmong': 'ມົ້ງ',
    'english': 'ອັງກິດ',
    'laos': 'ລາວ',
    'search': 'ຄົ້ນຫາ',
    'resetPassword': 'ກູ້ລະຫັດຜ່ານໃໝ່',
    'home': 'ໜ້າລັກ',
    
    'payment': 'ຊໍາລະເງິນ',
    'sorryOnlyCashOnDeliveryIsAvailable': 'ຂໍໂທດ, ພຽງແຕ່ມີເງິນສົດໃນການຈັດສົ່ງເທົ່ານັ້ນ',
    'paymentConfirm': 'ຢືນຢັນການຈ່າຍເງິນ',
    'productIsLoading': 'ສິນຄ້າກຳລັງໂຫລດຢູ່ ... !',
    'enterFirstName': 'ກະລຸນາໃສ່ຊື່',
    'enterLastName': 'ກະລຸນາໃສ່ນາມສະກຸນ',
    'invalidEmailAddress': 'ທີ່ຢູ່ອີເມວບໍ່ຖືກຕ້ອງ',
    'enterEmail': 'ກະລຸນາໃສ່ ອີເມວ',
    'enterPassword': 'ກະລຸນາໃສ່ລະຫັດຜ່ານ',
    'enterRepassword': 'ກະລຸນາຢືນຢັນລະຫັດຜ່ານ',
    'passwordDosenotMatch': 'ລະຫັດຜ່ານບໍ່ກົງກັນ',
    'thankUYouForYourOrder': 'ຂອບໃຈ ສຳລັບການສັ່ງຊື້ຂອງທ່ານງ',
    'orderIdReceiveAnEmailConfirmationShortlyAtEmail': 'ທ່ານຈະໄດ້ຮັບການຢືນຢັນອີເມວໃນເວລາສັ້ນໆທີ່ອີເມວທ່ານແລ້ວ',

    'somethingWentWrong': 'ມີບາງຢ່າງຜິດປົກກະຕິ',
    'pending': 'ທີ່ຍັງຄ້າງ',
    'deliverHere': 'ຈັດສົ່ງທີ່ນີ້',
    'addNewAddress': 'ເພີ່ມທີ່ຢູ່ ໃໝ່',
    'submitOrder': 'ສົ່ງໃບສັ່ງຊື້',

    'orderIdIs': 'id ສັ່ງຊື້ຂອງທ່ານແມ່ນແມ່ນ',

    'software': 'ຊັອບແວຣ໌',
    'service': 'ການບໍລິການ',
    'course': 'ຄອສອອນໄລ',
    '3d': '3D',
    'information': 'ຂໍ້ມູນຂ່າວສານ',
    'jobs': 'ຊອກວຽກ',
    'contact': 'ຕິດຕໍ່',
    'about': 'ກ່ຽວກັບ',
    'blog': 'Blog',

    //Home page
    'commingSoon': 'ກໍາລັງພັດທະນາຢູ່ ກະລຸນາເລືອກປະເພດສິນຄ້າ ກ່ອນສັ່ງຊື້...',

    //Product Detail
    'lak': ' ກີບ',
    'buyNow': 'ຊື້ດຽວນີ້',
    'addToCart': ' ເພີ່ມເຂົ້າກະຕ່າ',
    'description': 'ລາຍລະອຽດ:',
    'availableOffers': 'ຂໍ້ສະເໜີທີ່ມີຢູ່',
    'extra': 'ພິເສດ',
    'off': 'ປິດ',
    'ratings': 'ການໃຫ້ຄະແນນ',
    'reviews': 'ການທົບທວນຄືນ',

    //Cart Page
    'myCart': 'ກະຕ່າຂອງຂ້ອຍ',
    'deliverTo': 'ສົ່ງໃຫ້ທີ່',
    'placeOrder': 'ສະຖານທີ່ສັ່ງຊື້',

    //Cart Item
    'deliveryIn35Days': 'ຈັດສົ່ງພາຍໃນ 3 - 5 ວັນ',
    'price': 'ລາຄາ:',
    'remove': 'ເອົາອອກ',
    'saveForLater': 'ບັນທຶກໄວ້ໃນພາຍຫຼັງ',
    'deliveryCharges': 'ຄ່າສົ່ງ:',
    'items': 'ລາຍການ',
    'free': 'ຟຣີ',
    'totalAmount': 'ຈໍາ​ນວນ​ທັງ​ຫມົດ:',
    'priceDetails': 'ລາຍລະອຽດລາຄາ:',

    //Top menu
    'newCustomer': 'ລູກຄ້າໃໝ່ບໍ?',
    'explore': 'ສຳຫຼວດ',
    'plus': 'Plus',
    'more': 'ອື່ນໆ',
    'cart': 'ກະຕ່າ',

    //SignUp
    'or': 'ຫຼື',
    'register': 'ລົງທະບຽນ',

    //SIGNIN
    'requestOTP': 'ຮ້ອງຂໍ OTP',
    'emailMobile': "ອີເມວ/ເບີໂທລະສັບ",
    'enter': 'ກະລຸນາປ້ອນ',
    'getAccessToYourOrders': 'ເຂົ້າເຖິງຄຳສັ່ງ, ຄວາມຕ້ອງການແລະຂໍ້ສະເໜີແນະຂອງທ່ານ',

     //Check out
    'addressType': 'ປະເພດທີ່ຢູ່',
    'homeAddress': 'ເຮືອນ',
    'workAddress': 'ວຽກ',
    'saveAndDeliverHere': 'ຊ່ວຍປະຢັດແລະຈັດສົ່ງທີ່ນີ້',
    'addNewAddress': 'ເພີ່ມທີ່ຢູ່ໃໝ່',
    'orderSummary': 'ລວມຍອດສັ່ງຊື້ທັງໝົດ',
    'deliveryAddress':'ການຈັດສົ່ງທີ່ຢູ່',
    'deliveryLogin': 'ເຂົ້າສູ່ລະບົບ',
    'paymentOptions': 'ທາງເລືອກການຊໍາລະເງິນ',
    'continue': 'ສືບຕໍ່',
    'deliveryHere':'ການຈັດສົ່ງມາທີ່ນີ້',
    'cashOnDelivery':'ເງິນສົດໃນການຈັດສົ່ງ',
    'confirmOrder':'ຢືນຢັນການສັ່ງຊື້',
    'phoneNumber':'ເບີໂທລະສັບ',
    'moreActions':'ການກະທຳເພີ່ມເຕີມ',
    'downloadInvoice':'ດາວໂຫລດໃບເກັບເງິນ',
    'myAccount':'ບັນຊີຂອຂ້ອຍ',
    'myOrders':'ການສັ່ງຊື້ທັງໝົດ',
  },
};
