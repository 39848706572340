import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

import { I18nPropvider, LOCALES } from './i18n';
import HomePage from "./containers/HomePage";
import ProductListPage from "./containers/ProductListPage";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn, updateCart } from "./actions";
import ProductDetailsPage from "./containers/ProductDetailsPage";
import CartPage from "./containers/CartPage";
import CheckoutPage from "./containers/CheckoutPage";
import OrderPage from "./containers/OrderPage";
import OrderDetailsPage from "./containers/OrderDetailsPage";

import "./App.css";

function App() {
  const [locale, setLocale] = useState(LOCALES.ENGLISH);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.authenticate]);

  useEffect(() => {
    console.log("App.js - updateCart");
    dispatch(updateCart());
  }, [auth.authenticate]);

  return (
    <I18nPropvider locale={locale}>
       <div className="App">
       <div className="onTopMenu">
        <button className="enLanguage" onClick={() => setLocale(LOCALES.ENGLISH)}><FormattedMessage id='english' /></button>
          &nbsp;
          <button className="laLanguage" onClick={() => setLocale(LOCALES.LAOS)}><FormattedMessage id='laos' /></button>
          &nbsp;
          <button className="hmLanguage" onClick={() => setLocale(LOCALES.FRENCH)}><FormattedMessage id='hmong' /></button>
      </div>
       <Router>
         <Switch>
           <Route path="/" exact component={HomePage} />
           <Route path="/cart" component={CartPage} />
           <Route path="/checkout" component={CheckoutPage} />
           <Route path="/account/orders" component={OrderPage} />
           <Route path="/order_details/:orderId" component={OrderDetailsPage} />
           <Route
            path="/:productSlug/:productId/p"
            component={ProductDetailsPage}
          />
          <Route path="/:slug" component={ProductListPage} />
        </Switch>
      </Router>
    </div>
    </I18nPropvider>
  );
}

export default App;
