import React from 'react';
import { FormattedMessage } from 'react-intl';
import Header from '../../components/Header'
import Layout from '../../components/Layout'
import MenuHeader from '../../components/MenuHeader'

/**
* @author
* @function HomePage
**/

const HomePage = (props) => {
  return (
    <Layout>
      <h1 style={{textAlign: 'center'}}><FormattedMessage id='commingSoon' /></h1>
    </Layout>
  )

}

export default HomePage